<template>
  <div class="text-center">
    <h4 class="fw-bold">
    <h1>Volleyball</h1>
    <br>
    Overall Statistics for BYU (as of May 22, 2021)
    <br>
    (All games Sorted by Batting avg)
    </h4>
    <h5>
      <!-- Record: {{ gameswon }} - {{ gameslost }}    Home: {{ homewins }} - {{ homelosses }}   Away: {{ awaywins }} - {{ awaylosses }}   WCC: {{ wccwins }} - {{ wcclosses }} -->
    </h5>
  </div>

  <div class="table-responsive">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th>Player</th>
          <th>AVG</th>
          <th>GP-GS</th>
          <th>AB</th>
          <th>R</th>
          <th>H</th>
          <th>2B</th>
          <th>3B</th>
          <th>HR</th>
          <th>RBI</th>
          <th>TB</th>
          <th>SLG %</th>
          <th>BB</th>
          <th>HP</th>
          <th>SO</th>
          <th>GDP</th>
          <th>OB %</th>
          <th>SF</th>
          <th>SH</th>
          <th>SB</th>
        </tr>
      </thead>
      <tbody>
        <!-- replace below with v-for="player in roster" -->
        <tr>
          <td>Name</td>
          <td>17-10-2</td>
        </tr>
        <tr>
          <td>Totals</td>
          <td> 90</td>
        </tr>
        <tr>
          <td>Opponents</td>
          <td>80</td>
        </tr>
      </tbody>
    </table>
  </div>

</template>
