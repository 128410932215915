<template>
<div class="container">
  <div>
    <span>We hope to develop this statistics website to make it easier for you to collect, analyze, interpret and present empirical data for athletes.</span><br>
    <span>If you find or realize that the statistics are incorrect or that there is room for improvement, please do not hesitate to give us your opinion. </span>
  </div>
  <div>
    <span><strong>byucougars.com Web Developer</strong></span><br>
    <span>Tyler, Steele</span><br>
    <span>Forest, Lin</span><br>
    <span>Dillon, Kunz</span>
  </div>
</div>
</template>
