<!-- <link
href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
rel="stylesheet"
integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
crossorigin="anonymous"
/> -->
<!-- <script
src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
crossorigin="anonymous"
></script> -->
<template>
  <div class="text-center">
    <h4 class="fw-bold">
    2021 BYU Baseball
    <br>
    Overall Statistics for BYU (as of May 22, 2021)
    <br>
    (All games Sorted by Batting avg)
    </h4>
    <h5>
      <!-- Record: {{ gameswon }} - {{ gameslost }}    Home: {{ homewins }} - {{ homelosses }}   Away: {{ awaywins }} - {{ awaylosses }}   WCC: {{ wccwins }} - {{ wcclosses }} -->
    </h5>
  </div>
  <!-- Table for batters, constrain to only players who have at bats-->
  <div class="table-responsive" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th>Player</th>
          <th>AVG</th>
          <th>GP-GS</th>
          <th>AB</th>
          <th>R</th>
          <th>H</th>
          <th>2B</th>
          <th>3B</th>
          <th>HR</th>
          <th>RBI</th>
          <th>TB</th>
          <th>SLG %</th>
          <th>BB</th>
          <th>HP</th>
          <th>SO</th>
          <th>GDP</th>
          <th>OB %</th>
          <th>SF</th>
          <th>SH</th>
          <th>SB</th>
        </tr>
      </thead>
      <tbody>
        <!-- replace below with v-for="player in roster" -->
        <tr v-if="0!=0">
          <td>{{  this.totalPlayer.number }} {{ this.totalPlayer.name }}</td>
          <td>{{ this.totalPlayer.avgPct }}</td>
          <td>{{ this.totalPlayer.gpgs }}</td>
          <td>{{ this.totalPlayer.ab }}</td>
          <td>{{ this.totalPlayer.r }}</td>
          <td>{{ this.totalPlayer.h }}</td>
          <td>{{ this.totalPlayer.twob }}</td>
          <td>{{ this.totalPlayer.threeb }}</td>
          <td>{{ this.totalPlayer.hr }}</td>
          <td>{{ this.totalPlayer.rbi }}</td>
          <td>{{ this.totalPlayer.tb }}</td>
          <td>{{ this.totalPlayer.slgPct }}</td>
          <td>{{ this.totalPlayer.bb }}</td>
          <td>{{ this.totalPlayer.hp }}</td>
          <td>{{ this.totalPlayer.so }}</td>
          <td>{{ this.totalPlayer.gdp }}</td>
          <td>{{ this.totalPlayer.obPct }}</td>
          <td>{{ this.totalPlayer.sf }}</td>
          <td>{{ this.totalPlayer.sh }}</td>
          <td>{{ this.totalPlayer.sbatt }}</td>
        </tr>
        <tr>
          <td>Totals</td>
          <td>{{ this.totalBYU.avgPct }}</td>
          <td>{{ this.totalBYU.gpgs }}</td>
          <td>{{ this.totalBYU.ab }}</td>
          <td>{{ this.totalBYU.r }}</td>
          <td>{{ this.totalBYU.h }}</td>
          <td>{{ this.totalBYU.twob }}</td>
          <td>{{ this.totalBYU.threeb }}</td>
          <td>{{ this.totalBYU.hr }}</td>
          <td>{{ this.totalBYU.rbi }}</td>
          <td>{{ this.totalBYU.tb }}</td>
          <td>{{ this.totalBYU.slgPct }}</td>
          <td>{{ this.totalBYU.bb }}</td>
          <td>{{ this.totalBYU.hp }}</td>
          <td>{{ this.totalBYU.so }}</td>
          <td>{{ this.totalBYU.gdp }}</td>
          <td>{{ this.totalBYU.obPct }}</td>
          <td>{{ this.totalBYU.sf }}</td>
          <td>{{ this.totalBYU.sh }}</td>
          <td>{{ this.totalBYU.sb }}</td>
        </tr>
        <tr>
          <td>Opponents</td>
          <td>{{ this.totalOpp.avgPct }}</td>
          <td>{{ this.totalOpp.gpgs }}</td>
          <td>{{ this.totalOpp.ab }}</td>
          <td>{{ this.totalOpp.r }}</td>
          <td>{{ this.totalOpp.h }}</td>
          <td>{{ this.totalOpp.twob }}</td>
          <td>{{ this.totalOpp.threeb }}</td>
          <td>{{ this.totalOpp.hr }}</td>
          <td>{{ this.totalOpp.rbi }}</td>
          <td>{{ this.totalOpp.tb }}</td>
          <td>{{ this.totalOpp.slgPct }}</td>
          <td>{{ this.totalOpp.bb }}</td>
          <td>{{ this.totalOpp.hp }}</td>
          <td>{{ this.totalOpp.so }}</td>
          <td>{{ this.totalOpp.gdp }}</td>
          <td>{{ this.totalOpp.obPct }}</td>
          <td>{{ this.totalOpp.sf }}</td>
          <td>{{ this.totalOpp.sh }}</td>
          <td>{{ this.totalOpp.sb }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <h4 class="text-center fw-bold">(All games Sorted by Earned run avg)</h4>
  <!-- Table for pitchers, constrain to pitchers only -->
  <div class="table-responsive">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th>Player</th>
          <th>ERA</th>
          <th>W-L</th>
          <th>APP</th>
          <th>GS</th>
          <th>CG</th>
          <th>SHO</th>
          <th>SV</th>
          <th>IP</th>
          <th>H</th>
          <th>R</th>
          <th>ER</th>
          <th>BB</th>
          <th>SO</th>
          <th>2B</th>
          <th>3B</th>
          <th>HR</th>
          <th>B/AVG</th>
          <th>WP</th>
          <th>HP</th>
          <th>BK</th>
          <th>SFA</th>
          <th>SHA</th>
        </tr>
      </thead>
      <tbody>
        <!-- replace below with v-for="player in roster" -->
        <tr v-if="0!=0">
          <td>{{ this.totalPlayer.number }} {{ this.totalPlayer.name }}</td>
          <td>{{ this.totalPlayer.era }}</td>
          <td>{{ this.totalPlayer.w-l }}</td>
          <td>{{ this.totalPlayer.app }}</td>
          <td>{{ this.totalPlayer.gs }}</td>
          <td>{{ this.totalPlayer.cg }}</td>
          <td>{{ this.totalPlayer.sho }}</td>
          <td>{{ this.totalPlayer.sv }}</td>
          <td>{{ this.totalPlayer.ip }}</td>
          <td>{{ this.totalPlayer.h }}</td>
          <td>{{ this.totalPlayer.r }}</td>
          <td>{{ this.totalPlayer.er }}</td>
          <td>{{ this.totalPlayer.bb }}</td>
          <td>{{ this.totalPlayer.so }}</td>
          <td>{{ this.totalPlayer.twob }}</td>
          <td>{{ this.totalPlayer.threeb }}</td>
          <td>{{ this.totalPlayer.hr }}</td>
          <td>{{ this.totalPlayer.bavg }}</td>
          <td>{{ this.totalPlayer.wp }}</td>
          <td>{{ this.totalPlayer.hp }}</td>
          <td>{{ this.totalPlayer.bk }}</td>
          <td>{{ this.totalPlayer.sfa }}</td>
          <td>{{ this.totalPlayer.sha }}</td>
        </tr>
        <!-- <tr>
          <td>Totals</td>
          <td>{{ this.totalBYU.era }}</td>
          <td>{{ this.totalBYU.wl }}</td>
          <td>{{ this.totalBYU.app }}</td>
          <td>{{ this.totalBYU.gs }}</td>
          <td>{{ this.totalBYU.cg }}</td>
          <td>{{ this.totalBYU.sho }}</td>
          <td>{{ this.totalBYU.sv }}</td>
          <td>{{ this.totalBYU.ip }}</td>
          <td>{{ this.totalBYU.hPitch }}</td>
          <td>{{ this.totalBYU.rPitch }}</td>
          <td>{{ this.totalBYU.er }}</td>
          <td>{{ this.totalBYU.bbPitch }}</td>
          <td>{{ this.totalBYU.soPitch }}</td>
          <td>{{ this.totalBYU.twobPitch }}</td>
          <td>{{ this.totalBYU.threebPitch}}</td>
          <td>{{ this.totalBYU.hrPitch }}</td>
          <td>{{ this.totalBYU.bavgPct }}</td>
          <td>{{ this.totalBYU.wp }}</td>
          <td>{{ this.totalBYU.hpPitch }}</td>
          <td>{{ this.totalBYU.bk }}</td>
          <td>{{ this.totalBYU.sfa }}</td>
          <td>{{ this.totalBYU.sha }}</td>
        </tr>
        <tr>
          <td>Opponents</td>
          <td>{{ this.totalOpp.era }}</td>
          <td>{{ this.totalOpp.wl }}</td>
          <td>{{ this.totalOpp.app }}</td>
          <td>{{ this.totalOpp.gs }}</td>
          <td>{{ this.totalOpp.cg }}</td>
          <td>{{ this.totalOpp.sho }}</td>
          <td>{{ this.totalOpp.sv }}</td>
          <td>{{ this.totalOpp.ip }}</td>
          <td>{{ this.totalOpp.hPitch }}</td>
          <td>{{ this.totalOpp.rPitch }}</td>
          <td>{{ this.totalOpp.er }}</td>
          <td>{{ this.totalOpp.bbPitch }}</td>
          <td>{{ this.totalOpp.soPitch }}</td>
          <td>{{ this.totalOpp.twobPitch }}</td>
          <td>{{ this.totalOpp.threebPitch}}</td>
          <td>{{ this.totalOpp.hrPitch }}</td>
          <td>{{ this.totalOpp.bavgPct }}</td>
          <td>{{ this.totalOpp.wp }}</td>
          <td>{{ this.totalOpp.hpPitch }}</td>
          <td>{{ this.totalOpp.bk }}</td>
          <td>{{ this.totalOpp.sfa }}</td>
          <td>{{ this.totalOpp.sha }}</td>
        </tr> -->
      </tbody>
    </table>
  </div>
  <div class="text-center">
    <h4 class="fw-bold">
      2021 BYU Baseball
      <br>
      Overall Statistics for BYU (as of May 22, 2021)
      <br>
      (All games Sorted by Fielding pct)
    </h4>
  </div>

  <!-- Table for players with fielding positions, might not need to constrain -->
  <div class="table-responsive">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th>Player</th>
          <th>C</th>
          <th>PO</th>
          <th>A</th>
          <th>E</th>
          <th>FLD %</th>
          <th>DP</th>
          <th>SBA</th>
          <th>CSB</th>
          <th>SBA %</th>
          <th>PD</th>
          <th>CI</th>
        </tr>
      </thead>
      <tbody>
        <!-- replace below with v-for="player in roster" -->
        <tr v-if="0!=0">
          <td>{{ this.totalPlayer.number }} {{ this.totalPlayer.name }}</td>
          <td>{{ this.totalPlayer.c }}</td>
          <td>{{ this.totalPlayer.po }}</td>
          <td>{{ this.totalPlayer.a }}</td>
          <td>{{ this.totalPlayer.e }}</td>
          <td>{{ this.totalPlayer.fld }}</td>
          <td>{{ this.totalPlayer.dp }}</td>
          <td>{{ this.totalPlayer.sba }}</td>
          <td>{{ this.totalPlayer.csb }}</td>
          <td>{{ this.totalPlayer.sba }}</td>
          <td>{{ this.totalPlayer.pd }}</td>
          <td>{{ this.totalPlayer.ci }}</td>
        </tr>
        <!-- <tr>
          <td>Totals</td>
          <td>{{ this.totalBYU.c }}</td>
          <td>{{ this.totalBYU.po }}</td>
          <td>{{ this.totalBYU.a }}</td>
          <td>{{ this.totalBYU.e }}</td>
          <td>{{ this.totalBYU.fldPct }}</td>
          <td>{{ this.totalBYU.dp }}</td>
          <td>{{ this.totalBYU.sba }}</td>
          <td>{{ this.totalBYU.csb }}</td>
          <td>{{ this.totalBYU.sbaPct }}</td>
          <td>{{ this.totalBYU.pb }}</td>
          <td>{{ this.totalBYU.ci }}</td>
        </tr>
        <tr>
          <td>Opponents</td>
          <td>{{ this.totalOpp.c }}</td>
          <td>{{ this.totalOpp.po }}</td>
          <td>{{ this.totalOpp.a }}</td>
          <td>{{ this.totalOpp.e }}</td>
          <td>{{ this.totalOpp.fldPct }}</td>
          <td>{{ this.totalOpp.dp }}</td>
          <td>{{ this.totalOpp.sba }}</td>
          <td>{{ this.totalOpp.csb }}</td>
          <td>{{ this.totalOpp.sbaPct }}</td>
          <td>{{ this.totalOpp.pb }}</td>
          <td>{{ this.totalOpp.ci }}</td>
        </tr> -->
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ['selected', 'gameYears', 'seasonData'],
  data () {
    return {
      // For UI to format dates
      months: [
        'Jan.', // "January",
        'Feb.', // "February",
        'Mar.', // "March",
        'Apr.', // "April",
        'May.', // "May",
        'Jun.', // "June",
        'Jul.', // "July",
        'Aug.', // "August",
        'Sep.', // "September",
        'Oct.', // "October",
        'Nov.', // "November",
        'Dec.' // "December",
      ],
      totalBYU: null,
      totalOpp: null,
      showData: false
      // exclude: [
      //   'avgPct',
      //   'slgPct',
      //   'obPct',
      //   'gp-gs'
      // ]
    }
  },
  mounted () {
    console.log(this.seasonData)
    this.totalBYU = this.setTeamTotal()
    this.totalOpp = this.setOppTotal()
    this.showData = true
    console.log('BYU tb total: ' + this.totalBYU.tb)
    console.log('Opp tb total: ' + this.totalOpp.tb)
  },
  methods: {
    formDateStr (str) {
      var date = new Date(str)
      // need to double check if area different then show different data
      date.setHours(date.getHours() - 6)
      var dateStr = this.months[date.getMonth()] + ' ' + date.getDate()
      return dateStr
    },
    dateSort (a, b) {
      if (a.event_date < b.event_date) {
        return -1
      }
      if (a.event_date > b.event_date) {
        return 1
      }
      return 0
    },
    setTeamTotal () {
      const data = this.getBYUData(this.fixData(this.seasonData))
      const totalsBYU = {
        avgPct: this.getAVG(data), // total hits / total at bats, maybe (h/ab).toFixed(3)
        gpgs: this.getGP(data) + '-' + this.getGS(data),
        ab: this.getSumStat(data, 'hitting', 'ab'),
        r: this.getSumStat(data, 'hitting', 'r'),
        h: this.getSumStat(data, 'hitting', 'h'), // assumes that h represents singles, batting avg indicates that it may represent all hits total
        twob: this.getSumStat(data, 'hitting', 'double'),
        threeb: this.getSumStat(data, 'hitting', 'triple'), // can't find data for triples
        hr: this.getSumStat(data, 'hitting', 'hr'), // can't find data for home runs
        rbi: this.getSumStat(data, 'hitting', 'rbi'),
        tb: this.getTB(data), // can't find data for tb
        slgPct: this.getSLG(data), // maybe not correct, not sure because I can't access the baseball season stats api rn. If its wrong its either because of missing data or because h represents all hits and not just first base hits
        bb: this.getSumStat(data, 'hitting', 'bb'),
        hp: this.getSumStat(data, 'hitting', 'hbp'), // not sure if this is right, is hp referring to hbp, or hit by pitch
        so: this.getSumStat(data, 'hitting', 'so'),
        gdp: this.getSumStat(data, 'hitting', 'hitdp'), // not sure if this is right, is gdp (ground double play) referring to hitdp or hit double play
        obPct: this.getOBP(data), // ((h + bb + hp) / (ab + bb + hp + sf)).toFixed(3)
        sf: this.getSumStat(data, 'hitting', 'fly'), // not sure if this is right, is sf (sac fly) referring to fly
        sh: this.getSumStat(data, 'hitting', 'sh'),
        sb: this.getSumStat(data, 'hitting', 'sb') // instead of sb-att
        // start of fielding stats
        // c: this.getSumStat(data, 'fielding', 'c'),
        // po: this.getSumStat(data, 'fielding', 'po'),
        // a: this.getSumStat(data, 'fielding', 'a'),
        // e: this.getSumStat(data, 'fielding', 'e'),
        // fldPct: this.getFLD(this.po, this.a, this.e), // ((po + a) / (po + a + e)).toFixed(3)
        // dp: this.getSumStat(data, 'fielding', 'dp'),
        // sba: this.getSumStat(data, 'fielding', 'sba'),
        // csb: this.getSumStat(data, 'fielding', 'csb'),
        // sbaPct: 'placeholder',
        // pb: this.getSumStat(data, 'fielding', 'pb'),
        // ci: this.getSumStat(data, 'fielding', 'ci'),
        // start of pitching stats
        // era: 'placeholder',
        // wl: 'placeholder',
        // app: this.getSumStat(data, 'pitching', 'app'),
        // gs: this.getSumStat(data, 'pitching', 'gs'),
        // cg: this.getSumStat(data, 'pitching', 'cg'),
        // sho: this.getSumStat(data, 'pitching', 'sho'),
        // sv: this.getSumStat(data, 'pitching', 'sv'),
        // ip: 'placeholder',
        // hPitch: this.getSumStat(data, 'pitching', 'h'), // fix (given up)
        // rPitch: this.getSumStat(data, 'pitching', 'r'), // fix (given up)
        // er: this.getSumStat(data, 'pitching', 'er'), // fix
        // bbPitch: this.getSumStat(data, 'pitching', 'bb'), // fix (given up)
        // soPitch: this.getSumStat(data, 'pitching', 'so'), // fix (given up)
        // twobPitch: this.getSumStat(data, 'pitching', 'double'), // fix (given up)
        // threebPitch: this.getSumStat(data, 'pitching', 'triple'), // fix (given up)
        // hrPitch: this.getSumStat(data, 'pitching', 'hr'), // fix (given up)
        // bavgPct: 'placeholder',
        // wp: this.getSumStat(data, 'pitching', 'wp'),
        // hpPitch: this.getSumStat(data, 'pitching', 'hp'),
        // bk: this.getSumStat(data, 'pitching', 'bk'),
        // sfa: this.getSumStat(data, 'pitching', 'sfa'),
        // sha: this.getSumStat(data, 'pitching', 'sha')
      }
      return totalsBYU
    },
    setOppTotal () {
      const data = this.getOppData(this.fixData(this.seasonData))
      const totalsOpp = {
        avgPct: this.getAVG(data), // total hits / total at bats, maybe (h/ab).toFixed(3)
        gpgs: this.getGP(data) + '-' + this.getGS(data),
        ab: this.getSumStat(data, 'hitting', 'ab'),
        r: this.getSumStat(data, 'hitting', 'r'),
        h: this.getSumStat(data, 'hitting', 'h'), // assumes that h represents singles, batting avg indicates that it may represent all hits total
        twob: this.getSumStat(data, 'hitting', 'double'),
        threeb: this.getSumStat(data, 'hitting', 'triple'), // can't find data for triples
        hr: this.getSumStat(data, 'hitting', 'hr'), // can't find data for home runs
        rbi: this.getSumStat(data, 'hitting', 'rbi'),
        tb: this.getTB(data), // can't find data for tb
        slgPct: this.getSLG(data), // maybe not correct, not sure because I can't access the baseball season stats api rn. If its wrong its either because of missing data or because h represents all hits and not just first base hits
        bb: this.getSumStat(data, 'hitting', 'bb'),
        hp: this.getSumStat(data, 'hitting', 'hbp'), // not sure if this is right, is hp referring to hbp, or hit by pitch
        so: this.getSumStat(data, 'hitting', 'so'),
        gdp: this.getSumStat(data, 'hitting', 'hitdp'), // not sure if this is right, is gdp (ground double play) referring to hitdp or hit double play
        obPct: this.getOBP(data), // ((h + bb + hp) / (ab + bb + hp + sf)).toFixed(3)
        sf: this.getSumStat(data, 'hitting', 'fly'), // not sure if this is right, is sf (sac fly) referring to fly
        sh: this.getSumStat(data, 'hitting', 'sh'),
        sb: this.getSumStat(data, 'hitting', 'sb') // instead of sb-att
        // // start of fielding stats
        // c: this.getSumStat(data, 'fielding', 'c'),
        // po: this.getSumStat(data, 'fielding', 'po'),
        // a: this.getSumStat(data, 'fielding', 'a'),
        // e: this.getSumStat(data, 'fielding', 'e'),
        // fldPct: this.getFLD(this.po, this.a, this.e), // ((po + a) / (po + a + e)).toFixed(3)
        // dp: this.getSumStat(data, 'fielding', 'dp'),
        // sba: this.getSumStat(data, 'fielding', 'sba'),
        // csb: this.getSumStat(data, 'fielding', 'csb'),
        // sbaPct: 'placeholder',
        // pb: this.getSumStat(data, 'fielding', 'pb'),
        // ci: this.getSumStat(data, 'fielding', 'ci'),
        // // start of pitching stats
        // era: 'placeholder',
        // wl: 'placeholder',
        // app: this.getSumStat(data, 'pitching', 'app'),
        // gs: this.getSumStat(data, 'pitching', 'gs'),
        // cg: this.getSumStat(data, 'pitching', 'cg'),
        // sho: this.getSumStat(data, 'pitching', 'sho'),
        // sv: this.getSumStat(data, 'pitching', 'sv'),
        // ip: 'placeholder',
        // hPitch: this.getSumStat(data, 'pitching', 'h'), // fix (given up)
        // rPitch: this.getSumStat(data, 'pitching', 'r'), // fix (given up)
        // er: this.getSumStat(data, 'pitching', 'er'), // fix
        // bbPitch: this.getSumStat(data, 'pitching', 'bb'), // fix (given up)
        // soPitch: this.getSumStat(data, 'pitching', 'so'), // fix (given up)
        // twobPitch: this.getSumStat(data, 'pitching', 'double'), // fix (given up)
        // threebPitch: this.getSumStat(data, 'pitching', 'triple'), // fix (given up)
        // hrPitch: this.getSumStat(data, 'pitching', 'hr'), // fix (given up)
        // bavgPct: 'placeholder',
        // wp: this.getSumStat(data, 'pitching', 'wp'),
        // hpPitch: this.getSumStat(data, 'pitching', 'hp'),
        // bk: this.getSumStat(data, 'pitching', 'bk'),
        // sfa: this.getSumStat(data, 'pitching', 'sfa'),
        // sha: this.getSumStat(data, 'pitching', 'sha')
      }
      return totalsOpp
    },
    fixData (data) {
      var fixedData = []
      for (let i = 0; i < data.length; i++) {
        // hopefully handles the issue where game arrays have an extra empty array before the actual info
        if (data[i][0]) {
          data[i] = data[i][0]
        }
        // removes all games that don't include bsgame objects
        if (data[i].bsgame) {
          fixedData.push(data[i])
        } else {
          console.log('found an object without bsgame')
        }
      }
      return fixedData
    },
    getBYUData (data) {
      var dataBYU = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].bsgame.team[0].id === 'BYU') {
          // console.log('the first team was byu')
          dataBYU[i] = (data[i].bsgame.team[0].totals)
        } else {
          // console.log('the second team was byu')
          dataBYU[i] = (data[i].bsgame.team[1].totals)
        }
      }
      console.log(dataBYU)
      return dataBYU
    },
    getOppData (data) {
      var dataOpp = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].bsgame.team[0].id !== 'BYU') {
          // console.log('the first team was the opponent')
          dataOpp[i] = (data[i].bsgame.team[0].totals)
        } else {
          // console.log('the second team was the opponent')
          dataOpp[i] = (data[i].bsgame.team[1].totals)
        }
      }
      console.log(dataOpp)
      return dataOpp
    },
    getSumStat (data, categoryKey, statsKey) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i][categoryKey][statsKey]) {
          total += parseInt(data[i][categoryKey][statsKey])
        } else {
          console.log(statsKey + ' not found in ' + categoryKey)
        }
      }
      return total
    },
    // fix getGS and getGP
    getGS (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].gs) {
          total += data[i].gs - 0
        }
      }
      return total
    },
    getGP (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].gp) {
          total += data[i].gp - 0
        }
      }
      return total
    },
    getTB (data) {
      const total = (this.getSumStat(data, 'hitting', 'h') * 1 + this.getSumStat(data, 'hitting', 'double') * 2 + this.getSumStat(data, 'hitting', 'triple') * 3 + this.getSumStat(data, 'hitting', 'hr') * 4)
      return total
    },
    //     getWinGame (games) {
    //       let counter = 0
    //       for (let i = 0; i < games.length; i++) {
    //         if (parseInt(games[i].byu_score) > parseInt(games[i].opp_score)) counter += 1
    //       }
    //       return counter
    //     },
    //     getLoseGame (games) {
    //       let counter = 0
    //       for (let i = 0; i < games.length; i++) {
    //         if (parseInt(games[i].byu_score) < parseInt(games[i].opp_score)) counter += 1
    //       }
    //       return counter
    //     },
    //     calTotal (games, categoryKey, statsKey) {
    //       let total = 0
    //       for (let i = 0; i < games.length; i++) {
    //         if (games[i] && games[i][categoryKey] && games[i][categoryKey][statsKey]) {
    //           total += games[i][categoryKey][statsKey] - 0
    //         }
    //       }
    //       return total
    //     },
    //     getTotalsByYear (year) {
    //       return this.totalsByYear.filter(total => total.schedule_year === year)
    //     },
    //     getAVG (numer, denom) {
    //       if (denom === 0) return 0
    //       const avg = (numer / denom).toFixed(2)
    //       return avg
    //     },
    getSLG (data) {
      if (this.getSumStat(data, 'hitting', 'ab') === 0) return 0
      const slg = ((this.getTB(data)) / this.getSumStat(data, 'hitting', 'ab')).toFixed(3)
      return slg
    },
    getOBP (data) {
      // obp = (h + bb + hbp) / (ab + bb + hbp + sf)
      const num = (this.getSumStat(data, 'hitting', 'h') + this.getSumStat(data, 'hitting', 'bb') + this.getSumStat(data, 'hitting', 'hbp'))
      const denom = (this.getSumStat(data, 'hitting', 'ab') + this.getSumStat(data, 'hitting', 'bb') + this.getSumStat(data, 'hitting', 'hbp') + this.getSumStat(data, 'hitting', 'sf'))
      if ((denom) === 0) return 0
      const obp = ((num) / (denom)).toFixed(3)
      return obp
    },
    getFLD (po, a, e) {
      // fld = (po + a) / (po + a + e)
      if ((po + a + e) === 0) return 0
      const fld = ((po + a) / (po + a + e)).toFixed(3)
      return fld
    },
    getAVG (data) {
      const num = (this.getSumStat(data, 'hitting', 'h') + this.getSumStat(data, 'hitting', 'double') + this.getSumStat(data, 'hitting', 'triple') + this.getSumStat(data, 'hitting', 'hr'))
      const avg = (num / this.getSumStat(data, 'hitting', 'ab')).toFixed(3)
      return avg
    }
  }
}
</script>
